.banner {
  height: 100vh;
  background: 
  linear-gradient(rgba(122, 96, 96, 0.8), rgba(120, 97, 97, 0.8)), /* Darker gray overlay */
  url('../../assets/banner_img.jpg') center/cover no-repeat;
}

.box-image {
  max-width: 100px; /* Limits width for desktop view */
  max-height: 100px; /* Adjust as necessary */
  object-fit: contain; /* Maintains aspect ratio within bounds */
}



