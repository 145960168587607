:root {
    --bs-primary: rgb(240,76,83) !important;  /*  #f04c53 */
    --bs-secondary: #6c757d; 
}

.btn-primary {
    background-color: rgb(240,76,83) !important;
    font-weight: bold !important;
    border: none !important;
    border-radius: 0 !important;
}

.btn-secondary {
    font-weight: bold !important;
    border: none !important;
    border-radius: 0 !important;
}

